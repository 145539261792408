body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.editxnew.ant-modal {
  max-width: 1000px !important;
  width: 90% !important;
  height: 90% !important;
  overflow: hidden;
}

.editxnew .ant-modal-body {
  height: 100%;
}

.editxnew .ant-modal-content {

  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.editxnew .ant-modal-body {
  display: flex;
  flex-direction: column;
}



.countxnew.ant-modal {
  max-width: 1000px !important;
  width: 90% !important;
  height: 90% !important;
  overflow: hidden;
}

.countxnew .ant-modal-body {
  height: 100%;
  padding-bottom: 20px !important;
}

.countxnew .ant-modal-content {
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.countxnew .ant-modal-body {
  display: flex;
  flex-direction: column;
  max-height: calc(100% - 80px);
}

.cm-theme {
  overflow: hidden;
  flex: 1 1 auto;
}





.editx.ant-modal {
  padding: 0px !important;
  max-width: 1200px !important;
  width: 95% !important;
  height: 95% !important;
  overflow: hidden;
}

@media (max-width: 1200px) {
  .editx.ant-modal {
    width: 100% !important;
    height: 100% !important;
  }

  .editx .ant-modal-content {
    border-radius: 0px;
  }
}

.editx .ant-modal-content {
  padding: 0px !important;

  height: 100%;
  overflow: hidden;
}

.editx .ant-modal-body {
  height: 100%;
}

#xcoloring .ant-popover-inner {
  padding: 0px !important;
}

.ant-layout-sider-children {
  display: flex;
  flex-direction: column;
}

.language-tag .anticon {
  vertical-align: 0px !important;
}

#xrmymenu li {

  height: auto !important;
}

#xrmymenu li.ant-menu-item {
  padding-left: 15px !important;
  padding-top: 5px;
  padding-bottom: 5px;
}



#myadmin .ant-card-body {
  padding: 15px !important;
  padding-bottom: 10px !important;
}

#myadmin .ant-card-head {
  min-height: 35px !important;
  padding-left: 15px !important;
}

#myadmin img {
  width: 20px;
}

del {
  color: red;
  font-weight: bold;
}

ins {
  color: blue;
  font-weight: bold;
}

.hide-resize-observer {
  display: none !important;
}

.xrmnone .ant-select-selector {
  background: #fff !important;
}

.xrmauto .ant-select-selector {
  background: lightyellow !important;
}

.xrmfull .ant-select-selector {
  background: green !important;
  color: white !important;
}

.xrmreview .ant-select-selector {
  background: lightgreen !important;
}

.modalxnew.modalxnew .ant-modal-body {
  padding-bottom: 0px !important;
}